import classNames from "classnames"

import styles from "./CirclePlusIcon.module.scss"

export interface CirclePlusIconProps {
  open: boolean
  className?: string
}

export const CirclePlusIcon = ({ open, className }: CirclePlusIconProps) => {
  return <div className={classNames(styles.triggerIcon, className, { [styles.open]: open })}></div>
}
