import classNames from "classnames"
import Image from "next/legacy/image"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect, useRef, useState } from "react"

import iconBackArrow from "@/public/images/icon-back-arrow.svg"
import iconSecureWhite from "@/public/images/icon-secure-white.svg"
import iconSecure from "@/public/images/icon-secure.svg"
import logoBosam from "@/public/images/logo-bosam-black.svg"
import { HamburgerMenuIcon } from "@components/app-icons"
import { LocaleSwitcher } from "@components/locale-switcher"
import { useResponsiveLG } from "@hooks/shared"

import styles from "./Header.module.scss"

export interface HeaderProps {
  forLogin?: boolean
  onNavigateBack?: () => void
}

export const Header = ({ forLogin, onNavigateBack }: HeaderProps) => {
  const router = useRouter()
  const isResponsiveLG = useResponsiveLG()
  const [navbarAtTheTop, setNavbarAtTheTop] = useState(true)
  const [navbarHidden, setNavbarHidden] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const headerRef = useRef<HTMLElement>(null)
  const scrollPosition = useRef(0)
  const scrollUpPosition = useRef(0)

  // react to user scroll events
  useEffect(() => {
    const scrollHandler = () => {
      const { top } = document.body.getBoundingClientRect()

      // hide navbar
      if (top < 0 && top < scrollPosition.current) {
        setNavbarHidden(true)
        scrollUpPosition.current = 0
      }
      // show navbar
      else {
        if (scrollUpPosition.current === 0) {
          scrollUpPosition.current = Math.abs(top)
        } else if (top === 0 || scrollUpPosition.current - Math.abs(top) >= 50) {
          setNavbarHidden(false)
        }
      }

      // hide opaque background when navbar is at the top of the page
      if (Math.abs(top) <= window.innerHeight / 4) {
        setNavbarAtTheTop(true)
      } else {
        setNavbarAtTheTop(false)
      }

      // update scroll offset
      scrollPosition.current = top
    }
    window.addEventListener("scroll", scrollHandler)

    return () => window.removeEventListener("scroll", scrollHandler)
  }, [])

  // remove scrolling effect on body when menu is open
  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add("noscroll")
    } else {
      document.body.classList.remove("noscroll")
    }
  }, [menuOpen])

  // store links for later use
  const linkBosamLogo = (
    <Link href="/" style={{ height: logoBosam.height }}>
      <Image className={styles.brandLogo} src={logoBosam} alt="brand logo" priority />
    </Link>
  )
  const middleLinks = (
    <div className={styles.middleLinks}>
      <Link
        href="/bosam-modellen"
        className={classNames("menu-item", {
          [styles.active]: router.pathname === "/bosam-modellen",
        })}
      >
        Bosam-modellen
      </Link>
      <Link
        href="/medlemsprovning"
        className={classNames("menu-item", {
          [styles.active]: router.pathname === "/medlemsprovning",
        })}
      >
        Medlemsprövning
      </Link>
      <Link
        href="/hitta-bostad"
        className={classNames("menu-item", {
          [styles.active]: router.pathname === "/hitta-bostad",
        })}
      >
        Hitta bostad
      </Link>
      <Link
        href="/hallbarhet"
        className={classNames("menu-item", {
          [styles.active]: router.pathname === "/hallbarhet",
        })}
      >
        Hållbarhet
      </Link>
      <Link
        href="/for-fastighetsbolag"
        className={classNames("menu-item", {
          [styles.active]: router.pathname === "/for-fastighetsbolag",
        })}
      >
        För fastighetsbolag
      </Link>

      {/* Add locale switcher to full height menu on mobile */}
      {!isResponsiveLG && <LocaleSwitcher className={styles.localeSwitcherMobile} />}
    </div>
  )

  // compute header top offset
  const top = navbarHidden ? `-${headerRef.current?.clientHeight}px` : 0

  return (
    <header
      ref={headerRef}
      className={classNames(styles.header, { [styles.top]: navbarAtTheTop })}
      style={{ top }}
    >
      <div className={classNames(styles.wrapper, { [styles.menuOpen]: menuOpen })}>
        {/* Left-aligned links */}
        {isResponsiveLG && forLogin ? (
          <button className={styles.backLink} onClick={onNavigateBack}>
            <Image src={iconBackArrow} alt="icon" priority />
            <span>Tillbaka</span>
          </button>
        ) : (
          linkBosamLogo
        )}

        {/* Center-aligned links */}
        {isResponsiveLG && forLogin ? linkBosamLogo : middleLinks}

        {/* Right-aligned links */}
        <div className={styles.rightLinks}>
          {(!isResponsiveLG || !forLogin) && (
            <Link href="/logga-in" className={classNames(styles.loginLink)}>
              <span>Logga in</span>
              <Image src={isResponsiveLG ? iconSecure : iconSecureWhite} alt="icon" priority />
            </Link>
          )}

          {/* Add locale switcher to login page */}
          {forLogin && isResponsiveLG && <LocaleSwitcher abbr />}
        </div>

        {/* Show hamburger button on mobile */}
        {!isResponsiveLG && (
          <HamburgerMenuIcon
            className={styles.hamburger}
            open={menuOpen}
            onClick={() => setMenuOpen(previousValue => !previousValue)}
          />
        )}
      </div>
    </header>
  )
}
