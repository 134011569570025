import classNames from "classnames"
import { CSSProperties, SyntheticEvent } from "react"

import styles from "./HamburgerMenuIcon.module.scss"

interface HamburgerMenuIconProps {
  open: boolean
  onClick?: (e: SyntheticEvent) => void
  className?: string
  style?: CSSProperties
}

export const HamburgerMenuIcon = ({ open, onClick, className, style }: HamburgerMenuIconProps) => {
  return (
    <div
      className={classNames(styles.bars, className, { [styles.open]: open })}
      onClick={onClick}
      style={style}
    >
      <svg
        width="32px"
        height="32px"
        viewBox="0 0 48 48"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <line x1="0" y1="17" x2="48" y2="17" strokeWidth="2" />
          <line x1="0" y1="31" x2="48" y2="31" strokeWidth="2" />
        </g>

        <g>
          <line x1="0" y1="24" x2="48" y2="24" strokeWidth="2" />
          <line x1="0" y1="24" x2="48" y2="24" strokeWidth="2" />
        </g>
      </svg>
    </div>
  )
}
