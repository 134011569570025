import classNames from "classnames"
import Image from "next/legacy/image"

import { WPMedia } from "@/models"

import styles from "./HeroImage.module.scss"

export interface HeroImageProps {
  image: WPMedia
  className?: string
  wide?: boolean
}

export const HeroImage = ({ image, className, wide }: HeroImageProps) => {
  return (
    <div className={styles.heroImageWrapper}>
      <div className={classNames(styles.container, className, { [styles.wide]: wide })}>
        <Image
          src={image.mediaItemUrl}
          alt={image.altText}
          layout="fill"
          objectFit="cover"
          priority
        />
      </div>
    </div>
  )
}
