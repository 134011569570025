import classNames from "classnames"
import Image from "next/legacy/image"
import { ReactNode } from "react"

import { ACFHero, WPMedia } from "@/models"
import { resize } from "@/utils"
import { AppLinkProps, AppLinks } from "@components/app-link"
import { Wysiwyg } from "@components/wysiwyg"

import styles from "./Hero.module.scss"

export interface HeroProps {
  featuredImage: WPMedia
  hero: ACFHero
  variant?: "default" | "image-right" | "image-left"
  loose?: boolean
  links?: AppLinkProps[]
  imageHeight?: number
  fullHeight?: boolean
  className?: string
  children?: ReactNode
}

export const Hero = ({
  featuredImage,
  hero,
  loose,
  variant,
  links,
  imageHeight,
  fullHeight,
  className,
  children,
}: HeroProps) => {
  const { title, description } = hero
  const coverClasses = classNames(styles.cover, {
    [styles.coverImageRight]: variant === "image-right",
    [styles.coverImageLeft]: variant === "image-left",
  })
  const heroImg = resize(featuredImage, {
    height: Math.min(imageHeight ?? featuredImage.mediaDetails?.height ?? Infinity, 240),
  })

  return (
    <section className={className}>
      <div
        className={styles.wrapper}
        style={{
          maxWidth: loose ? 1128 : 900,
          minHeight: fullHeight ? "var(--app-height, 100vh)" : 500,
        }}
      >
        <div className={coverClasses}>
          <Image alt={featuredImage.altText} {...heroImg} priority />
          <div>
            <h1 className="app-heading">{title}</h1>
            {description && <Wysiwyg className={styles.description} content={description} />}
          </div>
        </div>

        <AppLinks className={styles.links} links={links} />
        {children}
      </div>
    </section>
  )
}
