import Image from "next/legacy/image"
import Link from "next/link"

import iconMail from "@/public/images/icon-mail.svg"
import iconMapMarker from "@/public/images/icon-map-marker.svg"
import iconPhone from "@/public/images/icon-phone.svg"
import logoBosam from "@/public/images/logo-bosam.svg"
import { LocaleSwitcher } from "@components/locale-switcher"
import { useGlobalContext } from "@contexts/global"
import { useResponsiveSM } from "@hooks/shared"

import styles from "./Footer.module.scss"

export const Footer = () => {
  const isResponsiveSM = useResponsiveSM()

  // retrieve footer data from global context
  const { footer } = useGlobalContext()
  const {
    socialMediaLinks,
    copyright,
    contactUs: { sectionTitle: contactUsTitle, address, telephoneNo, email },
    bosam: { sectionTitle: bosamTitle, links: bosamLinks },
    ovrigt: { sectionTitle: ovrigtTitle, links: ovrigtLinks },
    samarbetspartners: { sectionTitle: partnersTitle, logos },
  } = footer

  // generate the social media links elements
  const elSocialMediaLinks = socialMediaLinks.map(({ link, icon }) => (
    <a
      key={icon.mediaItemUrl}
      className="footer-social-media"
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Image
        className={styles.imageLink}
        src={icon.mediaItemUrl}
        alt={icon.altText}
        width="24"
        height="24"
      />
    </a>
  ))

  // generate Bosam links
  const elBosamLinks = bosamLinks.map(({ label, href }) => (
    <li key={label}>
      <Link href={href} className="footer-item">
        {label}
      </Link>
    </li>
  ))

  // generate Övrigt links
  const elOvrigtLinks = ovrigtLinks.map(({ label, href }) => (
    <li key={label}>
      <Link href={href} className="footer-item">
        {label}
      </Link>
    </li>
  ))

  // generate partner logos <li> elements
  const elPartnersLogos = logos.map(({ logo, link }) => (
    <li key={logo.mediaItemUrl}>
      <a href={link} className="footer-partner" target="_blank" rel="noopener noreferrer">
        <Image
          className={styles.imageLink}
          src={logo.mediaItemUrl}
          alt={logo.altText}
          width={logo.mediaDetails?.width}
          height={logo.mediaDetails?.height}
        />
      </a>
    </li>
  ))

  // convert year placeholder in copyright text
  const year = new Date().getFullYear()
  const parsedCopyright = copyright.replace(/{year}/g, year.toString())

  return (
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        {/* Main Footer Section */}
        <div className={styles.mainSection}>
          <Link href="/">
            <Image className={styles.imageLink} src={logoBosam} alt="Bosam logo" />
          </Link>
          <div className={styles.socialMediaLogos}>{elSocialMediaLinks}</div>
          {isResponsiveSM && <div className={styles.copyright}>{parsedCopyright}</div>}
          <LocaleSwitcher className={styles.localeButtons} />
        </div>

        <div className={styles.spacer}></div>

        <div className={styles.mainLinks}>
          {/* Contact Us Section */}
          <div className={styles.section}>
            <h3>{contactUsTitle}</h3>
            <ul>
              <li className={styles.itemWithIcon}>
                <Image src={iconMapMarker} alt="map icon" width="20" height="20" />
                <div className={styles.label} dangerouslySetInnerHTML={{ __html: address }}></div>
              </li>
              <li className={styles.itemWithIcon}>
                <Image src={iconPhone} alt="map icon" width="20" height="20" />
                <span className={styles.label}>
                  <a href={`tel:${telephoneNo}`}>{telephoneNo}</a>
                </span>
              </li>
              <li className={styles.itemWithIcon}>
                <Image src={iconMail} alt="map icon" width="20" height="20" />
                <span className={styles.label}>
                  <a href={`mailto:${email}`}>{email}</a>
                </span>
              </li>
            </ul>
          </div>

          {/* Bosam Pages Section */}
          <div className={styles.section}>
            <h3>{bosamTitle}</h3>
            <ul>{elBosamLinks}</ul>
          </div>

          {/* Övrigt Pages Section */}
          <div className={styles.section}>
            <h3>{ovrigtTitle}</h3>
            <ul>{elOvrigtLinks}</ul>
          </div>

          {/* Partners Section */}
          <div className={styles.section}>
            <h3>{partnersTitle}</h3>
            <ul>{elPartnersLogos}</ul>
          </div>
        </div>

        {!isResponsiveSM && <div className={styles.copyright}>{parsedCopyright}</div>}
      </div>
    </footer>
  )
}
