import { useRouter } from "next/router"
import { useCallback, useEffect } from "react"

import { ACFSeo, GQLGlobalFields } from "@/models"
import { Footer } from "@components/footer"
import { Header } from "@components/header"
import { Metadata } from "@components/metadata"
import { GlobalContext } from "@contexts/global"

export interface LayoutProps {
  children: React.ReactNode
  globalFields: GQLGlobalFields
  seo?: ACFSeo
}

const scrollCallback = () => {
  // reset position to the start of the page
  window.scrollTo(0, 0)

  // scroll to the target section - indicated as hash
  const hash = window.location.hash
  if (hash) document.querySelector(hash)?.scrollIntoView({ behavior: "smooth" })

  // scroll to the target section - indicated as query param
  const url = new URL(window.location.href)
  if (url.searchParams.get("target") === "interest")
    document.getElementById("form-interest")?.scrollIntoView()
}

export const Layout = ({ children, globalFields, seo }: LayoutProps) => {
  const { generalSettings, acfGlobalFields } = globalFields
  const metaTitle = seo?.title ?? generalSettings.title
  const metaDescription = seo?.metaDesc ?? generalSettings.description
  const opengraphImage = seo?.opengraphImage.mediaItemUrl ?? ""

  // handle manual back navigation on login page
  const router = useRouter()
  const navigateBackHandler = useCallback(() => router.back(), [router])

  // listen to window resize events to properly scale full-height elements
  useEffect(() => {
    const handler = () =>
      document.documentElement.style.setProperty("--app-height", `${window.innerHeight}px`)

    handler()
    window.addEventListener("resize", handler)

    return () => window.removeEventListener("resize", handler)
  }, [])

  // slide smoothly to the section indicated with the hash
  useEffect(() => {
    const timeout = setTimeout(scrollCallback, 500)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <GlobalContext.Provider value={acfGlobalFields.acfGlobal}>
      <Metadata title={metaTitle} description={metaDescription} image={opengraphImage} />
      <Header
        forLogin={router.pathname === "/logga-in"}
        onNavigateBack={navigateBackHandler}
      ></Header>
      {children}
      <Footer></Footer>
    </GlobalContext.Provider>
  )
}
