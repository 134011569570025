import classNames from "classnames"
import Link from "next/link"
import { useRouter } from "next/router"

import { setBrowserCookie } from "@/utils"
import { useGlobalContext } from "@contexts/global"

import styles from "./LocaleSwitcher.module.scss"

interface LocaleSwitcherProps {
  className?: string
  abbr?: boolean
}

export const LocaleSwitcher = ({ className, abbr }: LocaleSwitcherProps) => {
  const router = useRouter()
  const { locales } = useGlobalContext()

  // locale switcher should only be rendered in the login page
  if (router.pathname !== "/logga-in") return null

  const _localeButtons = Object.values(locales).map(({ locale, label }) => (
    <Link
      key={locale}
      href={router.asPath}
      locale={locale}
      className={router.locale === locale ? styles.localeActive : ""}
      onClick={() => setBrowserCookie(process.env.NEXT_PUBLIC_I18N_KEY!, locale)}
    >
      {abbr ? locale.toUpperCase() : label}
    </Link>
  ))

  return <div className={classNames(styles.container, className)}>{_localeButtons}</div>
}
